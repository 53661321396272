<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="批发发货通知明细" custom-class="custom-dialog-max-width" @closed="form = null">
    <template v-if="form">
      <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
        <div class="h sb">
          <form-info-item label="发货单号"> {{form.formCode}} </form-info-item>
          <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
        </div>
        <el-table border :data="form.items" highlight-current-row empty-text="没有商品信息" style="margin: 8px 0;">
          <el-table-column prop="code" label="商品编码" min-width="130" />
          <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
          <el-table-column label="商品" min-width="240">
            <div style="line-height: 1.5;" slot-scope="scope">
              <div>{{scope.row.goodsName}}</div>
              <div class="fc-g">{{$goodsSpecConvert(scope.row.specName)}}</div>
            </div>
          </el-table-column>
          <el-table-column prop="goodsCount" label="数量" align="right" width="80" />
          <el-table-column prop="goodsPrice" label="单价" align="right" width="80" :formatter="$price" />
          <el-table-column prop="discount" label="折扣" align="right" width="80" :formatter="$price" />
          <el-table-column label="合计" align="right" width="100" :formatter="v=>{ return $price(v.goodsCount*v.goodsPrice)}" />
          <el-table-column prop="dependFormCode" label="采购单号" width="130" />
          <el-table-column prop="info" label="备注" min-width="160" />
        </el-table>
        <div class="h r">
          <el-button @click="dialog = false">关闭</el-button>
          <!-- <el-button type="primary" @click="inbound" v-if="form.status === 2">入库</el-button> -->
        </div>
      </el-form>
    </template>
  </el-dialog>
</template>

<script>
import { getByCode } from "@/api/consignment";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      form: null
    };
  },
  methods: {
    loadDetail(code) {
      this.loading = true;
      getByCode(code)
        .then(res => {
          this.form = res;
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    resetForm(form) {
      if (form && form.formCode) {
        if (form.items) this.form = form;
        else this.loadDetail(form.formCode);
        this.dialog = true;
      }
    }
  }
};
</script>
