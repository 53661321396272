import request from '@/utils/request'


export function getOrderItem (orderId) {
  return request({
    url: 'api/invoice/unsendOrderForm/' + orderId,
    method: 'get'
  })
}

export function getByCode (orderCode) {
  return request({
    url: "api/orderSendForm/byFormCode/" + orderCode,
    method: "get"
  })
}

export function getMaxRebate (data) {
  return request({
    url: "api/orderSendForm/maxRebate",
    method: "post",
    data
  })
}

export function getRebates (params) {
  return request({
    url: "api/orderSendForm/payRebate",
    method: "get",
    params
  })
}

export function payRebate(data) {
  return request({
    url: "api/orderSendForm/payRebate",
    method: "post",
    data
  })
}

export function get (id) {
  return request({
    url: "api/orderSendForm/" + id,
    method: "get"
  })
}

export function add (data) {
  return request({
    url: "api/orderSendForm",
    method: "post",
    data
  })
}

export function edit (data) {
  return request({
    url: "api/orderSendForm",
    method: "put",
    data
  })
}

export function updateWarehouse (orderId, warehouseId) {
  return request({
    url: "api/invoice/updateWarehouse",
    method: "post",
    data: {
      id: orderId,
      warehouseId: warehouseId
    }
  })
}