<template>
  <div>
    <div class="head-container">
      <quick-select v-model="query.buyerId" url="api/distributor" placeholder="经销商" value-field="enterpriseId" filterable clearable @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" style="width: 320px;" />
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="输入销售单号搜索" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
    </div>
    <el-table ref="list" v-loading="loading" :data="data" highlight-current-row @current-change="handleOrderChange" style="width: 100%;">
      <el-table-column prop="formCode" label="销售单号" min-width="120" />
      <el-table-column prop="totalMoney" label="商品金额" width="120" align="right" :formatter="$price" />
      <el-table-column label="支付方式" width="120" :formatter="r => {return payTypes[r.payType];}" />
      <el-table-column prop="buyerName" label="经销商" width="160" />
      <el-table-column prop="purchaseTime" label="下单时间" width="150" :formatter="r => { return new Date(r.purchaseTime).format(); }" />
      <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <!-- <e-form ref="form" /> -->
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  data() {
    return {
      loading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      payTypes: ["线上支付", "线下支付"],
      current: null,
      query:{
        buyerId:null,
        dateRange:null,
        formCode:null,
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    handleOrderChange(row) {
      this.$emit("current-change", row);
    },
    clearSelect() {
      this.$refs.list && this.$refs.list.setCurrentRow();
    },
    beforeInit() {
      this.url = "api/orderForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { statusList: [2, 4, 5, 7], sort: "createAt,desc" },
        query
      );
      return true;
    },
    cancel() {
      this.$emit("cancel", this.reset);
    }
  }
};
</script>