<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.receiverId" filterable url="api/distributor" placeholder="经销商" value-field="enterpriseId" auto-select-first-option @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data">
      <el-table-column prop="formCode" label="单号" width="160" />
      <el-table-column label="单据日期" width="120" :formatter="r => { return new Date(r.realSendTime).format('yyyy/MM/dd'); }" />
      <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price" />
      <el-table-column prop="discount" label="折扣金额" width="120" align="right" :formatter="$price" />
      <el-table-column prop="receiverName" label="收货人" min-width="160" />
      <!-- <el-table-column label="收货地址" min-width="240">
        <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recDistrict}}{{scope.row.recCity}}{{scope.row.recAddress}}</template>
      </el-table-column> -->
      <!-- <el-table-column label="付款情况" align="center" width="100">
        <template slot-scope="scope">
          <el-tag :type="payStatus[scope.row.payStatus].type">{{payStatus[scope.row.payStatus].label}}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="inStockType" label="是否已入库">
        <template slot-scope="scope">
          <span v-if="scope.row.inStockType =='NONE'">未入库</span>
          <span v-else-if="scope.row.inStockType =='PART'">部分入库</span>
          <span v-else>已入库</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="formCode" label="发货单号" width="160" />
      <el-table-column prop="receiverName" label="收货人" width="160" />
      <el-table-column label="收货地址" min-width="240">
        <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recCity}}{{scope.row.recDistrict}}{{scope.row.recAddress}}</template>
      </el-table-column>
      <el-table-column label="创建时间" width="120" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd'); }" />
      <el-table-column prop="warehouseName" label="发货仓库" width="160" />
      <el-table-column label="预计发货时间" width="120" :formatter="r => { return new Date(r.planeTime).format('yyyy/MM/dd'); }" />
      <el-table-column label="商品金额" width="120" align="right" :formatter="r => {return '￥' + (r.goodsPriceAmount / 100).toFixed(2);}" />
      <el-table-column label="运费" width="100" align="right" :formatter="r => {return '￥' + (r.freight / 100).toFixed(2);}" />
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <el-dialog :visible.sync="order.show" title="选择销售单" append-to-body @closed="handleOrderClosed" width="960px">
      <order ref="order" @current-change="handleOrderSelect" />
      <div slot="footer">
        <el-button type="text" @click="order.show = false">取消</el-button>
        <el-button :loading="order.loading" :disabled="!order.current" type="primary" @click="handleOrderDetail">确认</el-button>
      </div>
    </el-dialog>

    <eForm ref="form" />
  </div>
</template>

<script>
import order from "./order";
import eForm from "./form";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { getOrderItem } from "@/api/consignment";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  components: { order, eForm },
  mixins: [initData],
  data() {
    let now = new Date();
    return {
      delLoading: false,
      downloadLoading:false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payTypes: ["线上支付", "线下支付"],
      status: {
        unsend: {
          type: "info",
          label: "未发货",
        },
        sended: {
          type: "success",
          label: "已发货",
        },
        received: {
          type: "primary",
          label: "已入库",
        },
      },
      payStatus: {
        unpay: {
          type: "info",
          label: "待付款",
        },
        pay_part: {
          type: "warning",
          label: "部分已付款",
        },
        pay_part_check: {
          type: "warning",
          label: "部分收款已确认",
        },
        pay: {
          type: "success",
          label: "已付款",
        },
        failed: {
          type: "danger",
          label: "付款失败",
        },
        finish: {
          type: "primary",
          label: "已完成",
        },
      },
      order: {
        show: false,
        loading: false,
        current: null,
      },
      order: {
        show: false,
        loading: false,
        current: null,
      },
      query: {
        receiverId: null,
        dateRange: [
          new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate()
          ).getTime(),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
          ).getTime(),
        ],
      },
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderSendForm/erp/page";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.startDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign({ sort: "createAt,desc" }, query);
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
    handleOrderClosed() {
      this.$refs.order && this.$refs.order.clearSelect();
      order.current = null;
    },
    handleOrderSelect(order) {
      this.order.current = order;
    },
    handleOrderDetail() {
      if (this.order.current) {
        this.order.loading = true;
        let o = {
          receiverId: this.order.current.buyerId,
          receiverName: this.order.current.receiverName,
          phone: this.order.current.receiverPhone,
          recProvince: this.order.current.recProvince,
          recProvinceCode: this.order.current.recProvinceCode,
          recCity: this.order.current.recCity,
          recCityCode: this.order.current.recCityCode,
          recDistrict: this.order.current.recDistrict,
          recDistrictCode: this.order.current.recDistrictCode,
          recAddress: this.order.current.recAddress,
          freight: 0,
          warehouseId: null,
          warehouseName: null,
          erpCode: null,
          items: [],
        };
        getOrderItem(this.order.current.id)
          .then((res) => {
            if (res && res.length) {
              o.items = res.map((item) => {
                return {
                  _itemId: this.$uuid(),
                  _stockInfo: {
                    loading: false,
                    error: false,
                    total: 0,
                  },
                  goodsId: item.goodsId,
                  goodsName: item.goodsName,
                  brandName: item.goodsBrand,
                  seriesName: item.goodsSeries,
                  specName: item.goodsSpec,
                  goodsCount: item.count,
                  goodsPrice: item.price,
                  goodsCode: item.code,
                  goodsErpCode: item.erpCode,
                  orderFormItemId: item.id,
                  dependFormCode:
                    this.order.current.erpFormCode ||
                    this.order.current.formCode,
                };
              });
              this.edit(o, true);
              this.order.show = false;
            } else {
              this.$notify({
                title: "该销售单已经进入发货流程或已完成发货",
                type: "success",
                duration: 2500,
              });
            }
          })
          .finally((_) => {
            this.order.loading = false;
          });
      }
    },
    toDownload() {
      if (!this.data || (this.data && this.data.length === 0)) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.page = 0;
      this.params.size = this.total;
      download("api/orderSendForm/erp/download", this.params)
        .then((result) => {
          downloadFile(result, "发货明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    print(id) {
      this.$print("wholesale-consignment", id);
    },
  },
};
</script>